body {
  margin: 0;
  box-sizing: border-box;
  padding: 50px;
}

* {
  margin: 0;
  box-sizing: border-box;
}

.hidden {
  display: none;
}

.underlined {
  text-decoration: underline;
}

@media screen and (max-width : 780px) {
  body {
    padding: 0;
    margin-top: 56px;
  }
}

@media screen and (min-width: 780px) and (max-width : 990px) {
  body {
    padding: 10px;
  }
}


